<template>
  <div class="media-modal" @click="onModalClick">
    <div
      :style="`width: ${ videoModal ? 'unset' : newImageWidth}px`"
      class="media-modal-content"
      :class="
        newImageWidth > maxImageWidth
          ? 'media-modal-content--width'
          : '' + (videoModal ? 'media-modal-content--video' : '')
      ">
      <button class="media-modal-close" @click="$emit('close')">
        <img src="@/assets/img/close_black.svg" />
      </button>
      <button v-if="!videoModal" :disabled="isFirstIndex" class="media-modal-prev" @click="$emit('slidePrev')">
        <i class="icon-chevron-left"></i>
      </button>
      <img v-if="!videoModal" :src="API_URL + currentImage.file.url" />
      <video
        v-if="videoModal && currentImage.video"
        :src="getAssetUrl(currentImage.video.url, true)"
        controls
        disablePictureInPicture
        controlslist="nodownload noplaybackrate" />
      <button v-if="!videoModal" :disabled="isLastIndex" class="media-modal-next" @click="$emit('slideNext')">
        <i class="icon-chevron-right"></i>
      </button>
    </div>
    <div class="media-modal-text">
      <div class="media-modal-name">{{ currentImage.name }}</div>
      <div class="media-modal-date">
        {{ currentImage.date | moment("DD MMMM YYYY") }}
      </div>
    </div>
  </div>
</template>

<script>
import '@/assets/sass/mediaModal.sass';
import { getAssetUrl } from '@/helpers';
import config from '@/config';
import $ from 'jquery';

export default {
  Name: 'MediaModal',
  props: {
    videoModal: {
      default: () => false,
      type: Boolean,
    },
    isFirstIndex: {
      default: () => false,
      type: Boolean,
    },
    isLastIndex: {
      default: () => false,
      type: Boolean,
    },
    currentImage: {
      default: () => {},
      type: Object,
    },
  },
  computed: {
    API_URL() {
      return config.API_URL;
    },
    newImageWidth() {
      if (this.videoModal) return 0;
      const { height, width } = this.currentImage.file;
      const newHeight = this.maxImageHeight;
      return (width * (newHeight / height)) + 30;
    },
    maxImageHeight() {
      const height = ($(window).height() * 78) / 100;
      return height;
    },
    maxImageWidth() {
      const width = $(window).width() - 30;
      if (width <= 1160) return width;
      return 1160;
    },
  },
  methods: {
    changeTextSize() {
      $('.media-modal-text').css(
        'width',
        `${$('.media-modal-content').width()}px`,
      );
    },
    onModalClick(event) {
      if (!event.target.classList.contains('media-modal')) return;
      this.$emit('close');
    },
    keydown(event) {
      if (event.keyCode === 37) {
        this.$emit('slidePrev');
      }
      if (event.keyCode === 39) {
        this.$emit('slideNext');
      }
      if (event.keyCode !== 27) return;
      this.$emit('close');
    },
    resize() {
      this.changeTextSize();
    },
    getAssetUrl(data) {
      return getAssetUrl(data);
    },
  },
  mounted() {
    document.addEventListener('keydown', this.keydown);
    window.addEventListener('resize', this.resize);
    this.changeTextSize();
  },
  updated() {
    this.changeTextSize();
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.keydown);
    window.removeEventListener('resize', this.resize);
  },
};
</script>
