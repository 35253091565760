<template>
  <div class="loader">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</template>

<script>
import '@/assets/sass/loader.sass';

export default {

};
</script>
