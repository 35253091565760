import config from '@/config';

export const getAssetUrl = (url) => config.API_URL + url;

export const getPreviewUrl = (preview, ascendingResolution = true) => {
  const {
    large, medium, small, thumbnail,
  } = preview.formats;
  let size = null;

  if (ascendingResolution) {
    size = large || medium || small || thumbnail || preview.origin;
  } else {
    size = thumbnail || small || medium || large || preview.origin;
  }

  return getAssetUrl(size.url);
};
